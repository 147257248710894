import React from 'react';
import Loadable from 'react-loadable';
import {
  PageHero,
  PageWithSubNav,
  Paragraph,
  SectionSubhead,
} from '../../../../components';
import pageHeroData from '../../../../data/pages/resources.yml';

const Button = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Button;
    return <Component {...props} />;
  },
});

const IconRestricted = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconRestricted;
    return <Component {...props} />;
  },
});

const Divider = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Divider;
    return <Component {...props} />;
  },
});

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="Plugins" subnav="resources">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Design Assets"
        tierThree="Plugins"
      />

      <SectionSubhead>Hudl Content Generator</SectionSubhead>
      <Paragraph>
        Quickly generate real content for your designs. Includes everything from
        names and emails to school and team names.
      </Paragraph>
      <Button
        size="small"
        buttonStyle="minimal"
        collapsePadding
        onClick={() => {
          if (typeof window !== 'undefined') {
            window.open(
              'https://www.figma.com/community/plugin/799937972923269683/Hudl-Content-Generator'
            );
          }
        }}>
        <IconRestricted /> Download
      </Button>
    </PageWithSubNav>
  );
};

export default IndexPage;
